import axios from "axios";

async function getPrice() {
  try {
    const response = await axios.get("price-conversion/1");
    return response.data;
  } catch (error) {
    return error;
  }
}

async function updatePrice(data) {
  try {
    const response = await axios.patch(`price-conversion/1`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export { getPrice, updatePrice };
