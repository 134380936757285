<template>
  <v-container>
    <v-row>
      <v-col class="col-5">
        <base-card heading="Price Conversion">
          <v-data-table :headers="headers" :items="price" hide-default-footer>
            <template v-slot:[`item.actions`]="{ item }">
              <v-list-item link @click="editItem(item)">
                <v-list-item-title class="caption">Edit</v-list-item-title>
              </v-list-item>
            </template>
          </v-data-table>
        </base-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="350px">
      <v-card>
        <v-card-title>
          <span class="text-h5"></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12" sm="12" md="12">
              <v-text-field label="Value" required v-model="editedItem.value" />
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseCard from "../../components/BaseCard.vue";
import { getPrice, updatePrice } from "./lib";
export default {
  components: { BaseCard },
  name: "PriceConversion",
  data() {
    return {
      price: [],
      headers: [
        { text: "Value", value: "value" },
        { text: "Edit", value: "actions" },
      ],
      dialog: false,
      editedItem: {
        id: "",
        value: "",
      },
    };
  },
  methods: {
    async initialize() {
      try {
        this.price = [];
        const data = await getPrice();
        this.price.push(data);
      } catch (error) {
        console.log(error);
      }
    },
    editItem(item) {
      this.editedItem = { ...item };
      this.dialog = true;
    },
    async save() {
      const body = {
        value: Number(this.editedItem.value),
      };
      await updatePrice(body);
      this.dialog = false;
      this.initialize();
      this.$notify({
        title: "Success",
        text: "Price Updated Successfully",
        type: "success",
      });
    },
  },
  async mounted() {
    await this.initialize();
  },
};
</script>

<style></style>
